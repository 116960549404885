// .modal-content {
//   max-width: 487px;
//   width: 100% !important;
//   border-radius: unset !important;
// }
.container-fluid {
  padding-right: 12px;
  padding-left: 12px;
}

#loginForm {
  section {
    text-align: unset !important;
  }

  .flex-column {
    margin: 0;
  }

  .login-form {
    &__title {
      font-size: 24px;
      text-align: center;
    }

    .alert {
      border-radius: unset;

      p {
        margin: 0;
      }
    }
  }

  .form-group {
    position: relative;
    margin-bottom: 0;

    & a {
      color: #007bff !important;
      &:hover {
        color: #0459b4 !important;
      }
    }

    & span {
      padding: 0 !important;
      font-weight: 700 !important;
    }

    & label {
      margin: 0;
    }

    &__input {
      max-width: 362px;
      width: 100%;
      height: 44px;

      border: 1px solid #999999 !important;

      padding: 0 10px;
    }

    &__button {
      max-width: 362px;
      width: 100%;
      height: 44px;

      font-size: 16px;
      font-weight: 700;

      color: #fff;
      border: none;

      &:hover {
        color: #fff;
        opacity: 0.7;
      }
    }

    &__label {
      font-size: 14px;
    }

    &__checkbox {
      width: 18px;
      height: 18px;

      background: #ffffff;
      border: 1px solid #999999;
      box-sizing: border-box;
      border-radius: 4px;

      vertical-align: bottom;
      position: relative;
      top: -1px;
    }
  }

  .btn-default {
    background-color: #AF7756;
  }

  .btn-facebook {
    max-width: 362px;
    width: 100%;

    background-color: #3b5998;
  }

  .btn-google {
    max-width: 362px;
    background-color: #4285f4;
  }

  .btn-signup {
    color: #AF7756;
    background-color: #fff;

    border: 1px solid#AF7756;

    &:hover {
      color: #AF7756;
      opacity: 0.8;
    }
  }

  .link:hover {
    text-decoration: underline;
  }

  .icon-size-btn {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 10px;
    left: 12px;
  }

  .input-error {
    border: 1px solid #b64b4b !important;
  }

  .aler-error {
    margin-bottom: unset;
    color: #b64b4b;
  }

  .required-mask {
    color: #AF7756;
  }

  .alert-server {
    padding-left: 16px;
    border: none;
  }

  .google-btn {
    max-width: 362px;
    width: 100%;
    border-radius: 1px !important;
    font-size: 16px !important;
    font-family: 'Noto Sans JP' !important;
    position: relative;
    color: #fff !important;
    box-shadow: none !important;
    background-color: #4285f4 !important;
    div {
      position: absolute;
      padding: 0 3px 2px 4px !important;
      left: 10px;
    }
  }

  .signup-btn {
    border: none;
    border-radius: 1px;
    color: #fff;
    height: 44px;
  }
}

button:disabled,
button[disabled] {
  opacity: 0.7;
  cursor: default !important;
}

.close-btn {
  position: absolute;
  top: 8px;
  right: 18px;

  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .form-group {
    .float-left {
      float: none !important;
    }
    .float-right {
      float: none !important;
    }
  }
}

input[type='checkbox']{
  display: inline-block;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
}
