@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts-file/NotoSansCJKjp-Bold.otf') format('opentype');
}
@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts-file/NotoSansCJKjp-Regular.otf') format('opentype');
}