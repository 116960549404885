@import '../../fonts/font-noto.scss';
@import '../../fonts/font-sarabun.scss';
@import '../../fonts/font-sawarabi.scss';
@import '../../fonts/font-noto-jp.scss';
@import '../../fonts/font-sawarabi-gothic.scss';
@import '../../fonts/font-roboto.scss';
@import '../../fonts/font-noto-sans-jp.scss';
@import '../../fonts/font-svn-quiche-sans.scss';
@import '../../fonts/font-noto-serif-cjk-jp.scss';

.housingMaterialMenu {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 145px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.housingMaterialMenuHide {
  position: relative;
}
#housingMaterialMenu {
  background-color: $color_white_2;
  z-index: 200;
  padding: 10px 0;
  li {
    display: inline;
    list-style: none;
  }
  .menu-item {
    display: inline-block;
    color: #000;
    line-height: 2.5rem;
    border-bottom: 3px solid transparent;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
      border-bottom: 3px solid $color_red_1;
    }
  }
  .right-space {
    margin-right: 2.5rem;
  }

  .active_item {
    color: $color_red_1 !important;
    border-bottom: 3px solid $color_red_1;
    &.none-text-decoration {
      border-bottom: none;
    }
  }

  .scroll-menu {
    display: flex;
    flex-wrap: wrap;

    overflow-x: auto;
  }

  .scroll-menu::-webkit-scrollbar {
    height: 0px;
  }
  .dropdown-menu {
    display: none;
    width: 230px;
    height: auto;
    position: absolute;
    top: 38px;
    left: 8%;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: unset;
    li {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #131313;
      display: list-item;
      padding: 6px 16px 6px 16px;

      a {
        color: #131313;
        text-decoration: none;
      }
      span {
        cursor: pointer;
        &:hover {
          color: #af7756;
        }
      }
    }
  }
  .types-menu-hover {
    position: relative;
    &:hover .dropdown-menu {
      display: block;
      a:hover {
        color: $color_red_1;
      }
    }
  }
}

#housingMaterialOverview {
  margin-top: 70px;
  @media screen and (min-width: 992px) {
    margin: 70px 0;
    .overview-block {
      &:first-child,
      &:last-child {
        .overview-content {
          bottom: 35px !important;
          padding-left: 140px;
          width: calc(100% + 85px);
          left: -100px;
        }
      }
      &:last-child {
        .overview-content {
          bottom: -64px !important;
        }
      }
      &:nth-child(2) {
        .overview-content {
          padding-right: 140px;
          width: calc(100% + 55px);
        }
        .overview-image {
          left: -45px;
        }
      }
    }
    .overview-content {
      position: relative;
    }
    .overview-image {
      height: 420px;
      width: auto;
      position: absolute !important;
      z-index: 100 !important;
    }
  }
  .overview-image {
    height: 420px;
    width: 100%;
    position: relative;
  }

  .overview-content {
    background-color: $color_white_2;
    bottom: -30px;
    padding: 36px;
    z-index: 50;
  }
  .company-philosophy-number {
    font-size: $fs_48;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: $color_black_2;
  }

  .overview-content-title {
    font-size: $fs_26;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: $color_black_2;
  }
  .overview-content-line {
    width: 60px;
    height: 3px;
    background-color: $color_red_1;
    margin-inline-end: unset;
    margin-inline-start: unset;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }
  .overview-content-description {
    font-size: $fs_18;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: $color_black_2;
  }
  .goto-website {
    color: blue;
  }
  .click-here {
    padding: 10px 0;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #131313;

    a {
      color: #af7756;
      cursor: pointer;
    }
  }
  .overview-block {
    position: relative !important;
    margin-top: 100px;
    &:first-child {
      margin-top: 0px;
    }
  }
  .block-button {
    width: 150px;
    height: 44px;
  }
  .btn-bg-red {
    background: $color_red_1;
    color: $color_white_2;
    border: 2px solid $color_red_1;

    &:hover {
      opacity: 0.8;
    }
  }
}

#housingMaterialProduct {
  margin-top: 70px;
  .product-image {
    &:nth-child(2) {
      position: absolute;
      top: 90px;
    }
  }
  .text-title {
    font-family: 'SVN-Quiche Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    color: #222222;
  }
  .text-description {
    font-family: 'Noto Sans JP';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: #777777;
  }

  .card-info {
    background-color: $color_red_1;
    color: $color_white_2;
    border-radius: 10px;
    width: 210px;
    padding-top: 30px;
    padding-bottom: 30px;
    .text-bolder {
      color: $color_white_2;
      font-size: 26px;
      font-weight: 700;
      font-family: 'Noto Sans JP';
    }
  }
  .list-advantages {
    list-style-image: url('../../../assets/images/element-list-style.svg');
    li {
      line-height: 32px;
    }
  }
  .card-feature {
    height: 100%;
    background-color: $color_white_2;
    padding: 30px 30px 15px;
  }
}
#housingMaterialGallery {
  margin-top: 70px;
  .title {
    color: #131313;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
  }

  .lazy-load-image-background.blur.lazy-load-image-loaded {
    width: 100%;
    height: 100%;
  }
}
