/* .serviceapartment-page {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.owl-item.active.center {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
/* For Mobile */
@media only screen and (max-width: 540px) {
  #serviceApartment {
    .project-info {
      margin: 25px 0px !important;
    }
    .like-contents {
      top: 60px !important;
      right: 30px !important;
    }
  }
  .extra-btn .block-button {
    width: 125px !important;
  }
  .item-per-page-container {
    font-size: 12px;
    .item-count-container,
    .text-sort,
    .item-sort option {
      font-size: 12px;
    }
  }
}
@media only screen and (max-width: 1100px) {
  #serviceApartment {
    .service-banner {
      height: 350px;
    }
  }
}

#serviceApartment,
#listOffers,
#searchResults,
#favourites {
  * {
    font-family: 'Noto Sans JP';
  }
  .content {
    width: 100%;
    padding: 20px 20px 20px 20px;
    height: auto;
  }
  .project-info {
    background-color: #ffffff;
    padding: 0;
    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    .project-name a {
      color: #222222;
    }
  }
  .project-name {
    display: block;
    height: 53px;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-decoration: unset;
    color: #222222;

    &:hover {
      color: $color_red_1 !important;
      transition: all 0.2s ease-in !important;
      text-decoration: none !important;
    }
  }
  .project-location {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }
  .apartment-address {
    display: flex;
    flex-direction: row;

    .address-description {
      height: 46px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .apartment-address img {
    position: relative;
    max-height: 24px;
    top: 3px;
  }
  .project-bed {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }
  .price-and-area {
    min-height: 49px;
  }
  .project-price {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #af7756;
    .text-through {
      color: #6b6b6b;
      text-decoration: line-through;
    }
  }
  .focus-price {
    font-weight: 700;
  }
  .like-contents {
    position: absolute;
    top: 45px;
    right: 30px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    &:hover {
      background: #90af3e;
    }
    svg {
      color: #ffffff;
      fill: #ffffff;
    }
  }
  .like-contents.active {
    background-color: #90af3e;
  }
  .container-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    max-width: 100%;
  }
  .slide {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .slide img {
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  .owl-carousel {
    display: flex;
    max-width: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    .owl-dots {
      position: absolute;
      top: 85%;
    }
    .owl-dot span {
      background-color: #ffffff;
      width: 8px;
      height: 8px;
      &:hover {
        background-color: hsl(0, 0%, 60%);
      }
    }
    .owl-dot.active span {
      background-color: #af7756;
    }
    .owl-nav {
      display: flex;
      flex-direction: row;
      top: 45%;
      padding: 0px 16px 0px 16px;
      width: 100%;
      justify-content: space-between;
    }
    .owl-prev {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: hsl(0, 0%, 85%);
      &:hover {
        background-color: hsl(0, 0%, 100%);
      }
    }
    .owl-prev.disabled {
      background-color: hsl(0, 0%, 85%);
    }
    .owl-next {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: hsl(0, 0%, 85%);
      &:hover {
        background-color: hsl(0, 0%, 100%);
      }
    }
    .owl-next.disabled {
      background-color: hsl(0, 0%, 85%);
    }
  }
  .apartment-area img {
    max-height: 24px;
  }
  .service-paginate {
    display: flex;
    flex-direction: row;
    list-style: none;
    left: 45%;
    margin: 20px 0px 40px 0px;
    justify-content: center;
    align-items: center;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        padding: 0 3px 0;
      }

      li:nth-child(1) {
        padding-right: 20px;
      }

      li:last-child {
        padding-left: 20px;
      }
    }
  }
  .next-btn {
    display: flex;
    width: 25px;
    height: 25px;
    margin: 0px 0px 0px 32px;
    &:hover {
      opacity: 0.7;
    }
  }

  .prev-btn {
    display: flex;
    margin: 0px 32px 0px 0px;
    width: 19px;
    height: 19px;
    &:hover {
      opacity: 0.7;
    }
  }
  .item-per-page-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 26px;
    flex-wrap: wrap;
  }
  .item-number {
    display: flex;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
  }
  .item-number option {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }
  .item-count-container {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-count-container span {
    height: 1px;
    width: 22px;
    background-color: #000000;
    margin: 0px 2px 0px 2px;
    transform: rotate(90deg);
  }
  .divided-line {
    height: 1px;
    width: 22px;
    background-color: #000000;
    margin: 0px 2px 0px 2px;
    transform: rotate(90deg);
  }
  .text-sort {
    font-size: 16px;
    line-height: 21px;
  }
  .item-sort {
    display: flex;
    border: none;
    outline: none;
    background: #eeeeee;
    cursor: pointer;
    option {
      background: #fff;
      font-size: 16px;
      line-height: 21px;
      cursor: pointer;
    }
  }
  .breadcrumb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45px;
  }
  .breadcrumb-home {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #999999;
    &:hover {
      color: #999999;
    }
  }
  .breadcrumb-service {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3f3f3f;
    &:hover {
      color: #3f3f3f;
    }
  }
  .overlay-text {
    position: absolute;
    opacity: 1;
    color: #ffffff;
    font-family: 'SVN-Quiche Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
    z-index: 3;
  }
  .service-banner {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .service-banner img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .color-overlay {
    background-color: #000000;
    opacity: 0.4;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
#listOffers {
  .block-button {
    width: 100%;
  }
  .project-info {
    margin: 25px 0 !important;
    width: 100% !important;
  }
  .like-contents {
    top: 35px !important;
    right: 30px !important;
  }
  .content {
    width: 100%;
    padding: 24px;
  }
  .project-card {
    height: 290px;
  }
  .special-offers {
    top: 35px;
  }
}
.special-offers {
  position: absolute;
  top: 45px;
  z-index: 1;
  left: 30px;
  .rectangle {
    border-radius: 0;
    height: 30px;
    width: 80px;
    background-color: #90af3e;
    &.rectangle-lg {
      width: 110px !important;
    }
  }
  .triangle-right {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 15px solid #90af3e;
    border-bottom: 15px solid transparent;
  }
  .text {
    width: 118px;
    position: absolute;
    top: 45%;
    left: 65%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    padding-left: 1px;
    margin-top: 0.9px;
  }
}
#searchResults {
  .like-contents {
    top: 30px;
    right: 30px;
  }
}

.margin-banner {
  margin-bottom: 10px;
}
