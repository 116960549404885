@font-face {
    font-family: 'Noto Serif CJK JP';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts-file/NotoSerifCJKjp-Bold.otf') format('opentype');
}
@font-face {
    font-family: 'Noto Serif CJK JP';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts-file/NotoSerifCJKjp-Regular.otf') format('opentype');
}