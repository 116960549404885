#usefulBanner {
  .useful-banner {
    width: 100%;
    height: 350px;

    position: relative;
  }

  .banner-wrap-text {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: absolute;
    top: 0;
    z-index: 3;
  }

  .banner-text-head {
    font-family: 'SVN-Quiche Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    color: #ffffff;
  }

  .banner-red-line {
    height: 6px;
    flex: none !important;
    border: solid#AF7756;
    background-color: #AF7756;
  }

  .banner-text-content {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }

  .banner-color-overlay {
    width: 100%;
    height: 100%;

    background: #000;
    opacity: 0.4;

    position: absolute;
    top: 0;
    z-index: 2;
  }
}

#menuUseful {
  .slide-category {
    margin: 30px 0 24px 0;
  }

  .menu-item {
    position: relative;

    .image {
      width: 100%;
      height: 100%;
    }

    .category-name {
      position: absolute;
      bottom: 0;

      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      color: #ffffff;
    }
  }
}

.faqs-title {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  color: #000000;

  padding-top: 86px;
}

.faqs-red-line {
  width: 78px;
  height: 0;
  border: 2px solid #AF7756;
  margin: 16px 0 32px 0;
  background-color: #AF7756;
}
