@font-face {
    font-family: 'SVN-Quiche Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts-file/SVN-QuicheSansBold.ttf') format('opentype');
}

@font-face {
    font-family: 'SVN-Quiche Sans Italic';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts-file/SVN-QuicheSansBoldItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'SVN-Quiche Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts-file/SVN-QuicheSansRegular.ttf') format('opentype');
}

@font-face {
    font-family: 'SVN-Quiche Sans Italic';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts-file/SVN-QuicheSansRegularItalic.ttf') format('opentype');
}