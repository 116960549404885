.header-top_user-name {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #131313;

  padding-left: 0 !important;
}

#subMenuAccount {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #222222;

  .sub-menu {
    &_backgr {
      background-color: #fafafa;
      padding: 14px 0;
      margin-bottom: 27px;
    }

    &_active-fixed {
      position: fixed;
      width: 100%;
      z-index: 9;
      top: 145px;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.3s;
    }

    &_items {
      margin: 0px 20px;

      &:first-child {
        margin-left: 0px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    &_active {
      color: #af7756;
    }
  }
}

#manageAccount {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #222222;

  .personal-detail {
    &__left {
      text-align: center;
      background-color: #ffffff;

      &-avatar {
        padding: 32px 0 24px 0;

        img {
          border-radius: 50%;
          width: 142px;
          height: 142px;
        }
      }

      &-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        color: #131313;
      }

      &-change_avatar {
        background: #af7756;
        font-size: 16px;
        line-height: 23px;
        color: #ffffff;
        border: unset;

        padding: 4px 18px;

        &:hover {
          opacity: 0.8;
        }
      }

      &-personal_detail {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #ffffff;

        padding: 5px 0;
        margin: 23px 32px 5px 32px;

        img {
          padding-left: 20px;
        }

        span {
          color: #222222;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

      &-span-active {
        background: #f7f1ee;
        span {
          color: #af7756;
        }
      }
    }

    &__right {
      padding: 32px;
      margin-bottom: 70px;
      background-color: #ffffff;

      &-title {
        font-size: 24px;
        line-height: 35px;

        padding-bottom: 20px;
      }

      &-button {
        &_cancel {
          font-weight: bold;
          font-size: 16px;
          line-height: 23px;
          color: #af7756;

          padding: 10.5px 29px;
          margin-right: 32px;

          background: #ffffff;
          border: 1px solid #af7756;
          box-sizing: border-box;
        }

        &_save {
          font-weight: bold;
          font-size: 16px;
          line-height: 23px;
          color: #ffffff;

          padding: 10.5px 29px;

          background: #af7756;
          border: 1px solid #af7756;
          box-sizing: border-box;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      &-email {
        color: #999999;
      }
    }
  }
}

#listBooking {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #222222;

  .list-booking {
    &_title {
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      color: #131313;

      margin-top: 24px;

      &:first-child {
        margin-top: 0px;
      }

      &-desc {
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 29px;
        color: #222222;

        margin-top: 12px;

        &:last-child {
          margin-bottom: 50px;
        }
      }
    }

    &_boder {
      background-color: #999999;
      border: 1px solid #999999;

      margin: 40px 0;
    }
  }

  .booking-date {
    font-size: 16px;
    line-height: 23px;
    color: #222222;

    margin-top: 18px;

    &:first-child {
      margin-bottom: 12px;
    }
  }
}

#favourites {
  font-family: Noto Sans JP !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #222222;

  .favourites {
    &-notFound {
      text-align: center;
      margin: 70px 0 135px 0;

      &_desc {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 31px;
        color: #131313;

        margin-top: 35px;
      }
    }

    &-checkbox {
      display: block;
      position: relative;
      padding-left: 27px;
      cursor: pointer;
      font-size: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &_select-one {
        position: absolute;
        z-index: 3;
        width: 20px;
        height: 20px;
      }

      &_text {
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: #222222;

        &:hover {
          opacity: 0.7;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border: 1px solid #131313;
        box-sizing: border-box;
        border-radius: 2px;
      }

      &:hover input ~ .checkmark {
        background-color: #ccc;
      }

      & input:checked ~ .checkmark {
        background-color: #90af3e;
      }

      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      & input:checked ~ .checkmark:after {
        display: block;
      }

      & .checkmark:after {
        left: 5px;
        top: 2px;
        width: 7px;
        height: 11px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &-trash {
      img {
        margin-right: 5px;
      }

      span {
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: #222222;
        color: #990000;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .project-info {
    position: relative;
  }

  .select-contents {
    width: 38px;
    height: 38px;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 3;
    border-radius: 50%;

    background-color: #f5f7f5;
  }

  .select-check {
    top: 9px !important;
    left: 9px !important;
  }
}

#removeFavoritesModal {
  max-width: 589px;

  .modal-content {
    max-width: 589px !important;
    width: 100% !important;
    border-radius: unset !important;
  }

  .remove-favorites {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #222222;

    &_trash-icon {
      margin-top: 43px;
      margin-bottom: 24px;
    }

    &_title {
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;

      margin-bottom: 12px;
    }

    &_desc {
      line-height: 23px;

      margin-bottom: 32px;
    }

    &_button {
      margin-bottom: 40px;

      &-no {
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        color: #af7756;

        background: #ffffff;
        border: 1px solid #af7756;
        box-sizing: border-box;

        padding: 11px 35px;
        margin-right: 24px;

        &:hover {
          opacity: 0.8;
        }
      }

      &-yes {
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        color: #ffffff;

        background: #af7756;
        border: unset;

        padding: 11px 35px;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .header-top_user-name {
    display: none !important;
  }
}
