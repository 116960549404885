
.contaiter-map {
    display: flex;
    width: 100%;
    padding: 15px 0;
    // .gm-ui-hover-effect {
    //     display: none !important;
    // }
    .width-location {
        width: calc(100% - 350px);
    }
    .card-location{
        width: 350px;
        height: 600px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .card-location-mobile {
        display: none;
    }
    .card-location::-webkit-scrollbar {
        width: 5px;
        background-color: #c7c7c7;
    }
    .card-body-location{
        display: block;
        padding: 0px;
        .card-title-location{
            text-align: center;
            color: #AF7756;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .card-border {
            width: 100%;
            height: 6px;
            background-color: #AF7756;
        }
        .container-card-panel {
            .card-panel {
                color: #333;
                background-color: #f3f3f3;
                border-bottom-color: #b1b1b1;
                display: block;
                text-align: left;
                padding: 8px;
                border-bottom: 1px solid #999999;
                font-size: 14px;
                font-weight: 700;
                font-style: normal;
                font-stretch: normal;
                text-transform: uppercase;
                height: 40px;
            }
            .img-option {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
            .content-card {
                height: 95px;
                padding: 10px;
                display: flex;
                .img-photo {
                    width: 80px;
                    height: 75px;
                    object-fit: scale-down;
                }
                .content-card-child {
                    margin-left: 10px;
                    font-size: 13px;
                }
                .distance {
                    width: calc(100% - 95px);
                    margin-left: 10px;
                    // padding: 10px 0;
                    font-size: 13px;
                    height: 75px;
                    display: inline-grid;
                    padding: 10px 0;
                
                }
            }
            
        }

        .content-address {
            display: block;
            font-size: 13px;
            padding: 10px;
            .address {
                margin-bottom: 10px;
                display: flex;
            }
            .phone {
                
            }
        }
        .content-curent {
            font-size: 13px;
            padding: 0 10px;
            min-height: auto;
            max-height: 100px;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .new-title-location {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            margin: 27px 20px 25px;
            color: #AF7756;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .img-new {
            text-align: center;
            display: flex;
            width: 100%;
            justify-content: center;
            .img-new-location {
                width: 100%;
                height: 210px;
                object-fit: cover;
                display: block;
                text-align: center;
            }
        }
        .new-content {
            margin: 27px 20px 25px;
            display: flex;
            font-size: 13px;
        }
        .img-balloon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 10px;
        }
        .img-walking {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .container-letter {
        background-color: #FFFFFF;
        opacity: 1;
        color: #222222;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        width: 250px;
        height: 60px;
        overflow: hidden;
        display: inline-flex;
        .letter-content {
            margin-left: 10px;
            justify-content: center;
            align-items: center;
            &__title {
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1;
            }
            div {
                display: flex;
            }
            .letter-content-img{
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }
        .letter-img {
            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
            }
        }
    }
}
.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    float: right;
    display: inline;
    justify-content: center;
}

.arrow-right {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    float: right;
    display: inline;
    justify-content: center;
    margin-top: -27px;
}

.right-location {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
  
.left-location {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
  
.up-location {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 11px;
}
  
.down-location {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: 5px;
}

.arrow-left {
    border: solid #AF7756;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    float: left;
    display: inline;
    justify-content: center;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-top: 4px;
}

.gm-style-iw-c {
    padding: 6px !important;
    
}
.gm-style-iw-d {
    overflow: auto !important;
}


.hide_right {
    display: none;
}
.hide_left{
    display: none;
}

@media only screen and (max-width: 767px) { 
    .padding-0 {
        padding: 0;
    }
    .contaiter-map {
        padding: 0;
        .width-location {
            width: 100% ;
        }
        .hide_right {
            display: block;
            background: #fff;
            position: absolute;
            right: 0;
            bottom: 300px;
            cursor: pointer;
        }
        .hide_left {
            display: block;
            background: #fff;
            position: absolute;
            right: 90%;
            bottom: 300px;
            cursor: pointer;
        }
        .card-location-mobile {
            width: 90%;
            height: 600px;
            overflow-x: hidden;
            position: absolute;
            right: 0;      
            display: block;
        }
        
    }
    
}
