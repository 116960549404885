* {
  font-family: 'Noto Sans JP';
}
.logo {
  margin-top: 40px;
}
.required-mask {
  color: #AF7756;
}
.forgot-form {
  width: 361px;
  margin-top: 30px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
}
.forgot-modal {
  margin-top: -60px;
}
.input-email {
  border-radius: 1px;
  border: 1px solid #999999;
  height: 44px;
  width: 100%;
  padding-left: 10px;
}
.input-email-err {
  border-radius: 1px;
  border: 1px solid #b64b4b;
  height: 44px;
  width: 100%;
  padding-left: 10px;
}
.remind-text {
  font-size: 14px;
  line-height: 18px;
  color: #131313;
  font-weight: normal;
  font-style: normal;
}
.reset-button {
  width: 100%;
  height: 44px;
  color: #ffffff;
  font-size: 16px;
  background-color: #AF7756;
  border-color: #AF7756;
  border-radius: 1px;
  font-weight: bold;
  border: none;
  &:hover {
    opacity: 0.7;
  }
}
.back-to-login {
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
  font-style: normal;
  color: #131313;
  margin-bottom: 41px;
}
.back-to-login a {
  color: #007bff !important;
}
a:link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
// .modal-content {
//   width: 487px;
//   justify-content: center;
//   align-items: center;
// }

.message-err {
  background: #f8d7da;
  color: #131313;
  font-size: 16px;
  line-height: 21px;
  border-radius: 1px;
}

.message-succ {
  background: #93e5bf;
  color: #131313;
  font-size: 16px;
  line-height: 21px;
  border-radius: 1px;
}
.email-validate-error {
  p {
    font-size: 16px;
    line-height: 21px;
    color: #b64b4b;
  }
}

@media only screen and (max-width: 768px) {
  .forgot-form {
    padding: 12px;
  }
}
