* {
  font-family: 'Noto Sans JP';
}
.signup-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
}
.field-name {
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
}
.field-input {
  background: #ffffff;
  border: 1px solid #999999;
  box-sizing: border-box;
  height: 44px;
  font-style: normal;
  padding: 0 10px;
}
.field-err {
  background: #ffffff;
  border: 1px solid #b64b4b;
  box-sizing: border-box;
  height: 44px;
  font-style: normal;
  padding: 0 10px;
}
::-webkit-input-placeholder {
  font-style: normal !important;
}
:-moz-placeholder {
  font-style: normal !important;
}
::-moz-placeholder {
  font-style: normal !important;
}
:-ms-input-placeholder {
  font-style: normal !important;
}
.required-mask {
  color: #AF7756;
}
.form-signup {
  padding: 40px 69.5px 40px 69.5px;
  width: 500px;
  background-color: #fff;
}
.sub-btn {
  background: #AF7756;;
  height: 44px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  border: none;
  &:hover {
    background-color: #AF7756;
    border-color: #AF7756;
    opacity: 0.7;
  }
}
.confirm-text {
  font-size: 16px;
  line-height: 21px;
  color: #131313;
  text-align: left;
}
.error-message {
  text-align: left;
  color: #b64b4b;
  font-size: 16px;
  line-height: 21px;
}

input[disabled] {
  background-color: #f2f2f2 !important;
}
.expried-page {
  margin-top: 80px;
  padding-bottom: 4rem;
  .title-expried {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: #131313;
  }
  
  .text-expried {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #131313;
    max-width: 633.99px;
  }
  
  .button-expried {
    background: #AF7756;;
    border: none;
    color: #fff;
    width: 256.61px;
    height: 46px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 576px) {
  .form-signup {
    padding: 25px;
    width: 400px;
  }
}

@media only screen and (max-width: 425px) {
  .form-signup {
    padding: 15px;
    width: 300px;
  }
  .expried-page {
    .title-expried {
      font-size: 18px;
    }
  }
}
