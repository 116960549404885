.project-cover {
  display: flex;
  justify-content: center;
  align-items: center;
}
#featuredProjects {
  * {
    font-family: 'Noto Sans JP';
  }
  .content {
    width: 100%;
    padding: 25px 25px 25px 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .project-info {
    margin: 25px 8px 25px 8px;
    width: 100%;
    height: 100%;
    padding: 0;
    &:hover {
      cursor: pointer;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
  .project-card {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }
  .projects-name {
    display: block;
    height: 100%;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 30px !important;
    line-height: 39px !important;
    color: #222222;
  }
  .listing {
    width: 100%;
    padding: 20px 15px 20px 15px;
  }
  .location {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #222222;
  }
  .project-description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }
  .project-listing {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #AF7756;
  }
  .project-location {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }
  .container-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    max-width: 100%;
    overflow: hidden;
  }
  .slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
  }
  .slide img {
    object-fit: contain;
    width: 100%;
  }
  .service-paginate {
    display: flex;
    flex-direction: row;
    list-style: none;
    left: 45%;
    margin: 20px 0px 40px 0px;
    justify-content: center;
    align-items: center;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        padding: 0 3px 0;
      }

      li:nth-child(1) {
        padding-right: 20px;
      }

      li:last-child {
        padding-left: 20px;
      }
    }
  }
  .next-btn {
    display: flex;
    width: 25px;
    height: 25px;
    margin: 0px 0px 0px 32px;
    &:hover {
      opacity: 0.7;
    }
  }

  .prev-btn {
    display: flex;
    margin: 0px 32px 0px 0px;
    width: 19px;
    height: 19px;
    &:hover {
      opacity: 0.7;
    }
  }
  .item-per-page-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 26px;
    flex-wrap: wrap;
  }
  .item-number {
    display: flex;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
  }
  .item-number option {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
  }
  .item-count-container {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-count-container span {
    height: 1px;
    width: 22px;
    background-color: #000000;
    margin: 0px 2px 0px 2px;
    transform: rotate(90deg);
  }
  .breadcrumb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45px;
  }
  .breadcrumb-home {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #999999;
    &:hover {
      color: #999999;
    }
  }
  .breadcrumb-service {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3f3f3f;
    &:hover {
      color: #3f3f3f;
    }
  }
  .overlay-text {
    position: absolute;
    opacity: 1;
    color: #ffffff;
    font-family: 'SVN-Quiche Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
    z-index: 3;
  }
  .service-banner {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .service-banner img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 351px;
  }
  .color-overlay {
    background-color: #000000;
    opacity: 0.4;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  /* @media screen and (max-width: 768px) {
    .project-info {
      width: 90%;
      height: 70%;
    }
    .container-slider {
      height: 290px;
    }
    .slide {
      width: 60%;
      height: 60%;
    }
  } */
}

