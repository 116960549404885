.public-layout {
  background-color: #eeeeee;
}

#detailApartmentBanner {
  .aparment-banner {
    position: relative;
    width: 100%;
    height: 351px;

    &__info {
      position: absolute;
      top: 0;

      width: 100%;
      height: 100%;

      z-index: 3;

      &--title {
        font-family: 'SVN-Quiche Sans';
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: #ffffff;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        white-space: break-spaces;
      }

      &--address {
        font-family: 'Noto Sans JP';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #ffffff;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        white-space: break-spaces;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      max-height: 351px;

      object-fit: cover;
    }

    &__color-overlay {
      width: 100%;
      height: 100%;

      background: #000;
      opacity: 0.4;

      position: absolute;
      top: 0;
      z-index: 2;
    }
  }
}
.detailApartmentMenu {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 145px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.detailApartmentMenuHide {
  position: relative;
}
#detailApartmentMenu {
  background-color: #ffffff;
  //padding-top: 10px;
  .menu-apartment-item {
    display: inline-block;
    color: #000;

    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    white-space: nowrap;

    &:hover {
      text-decoration: underline #000;
    }
  }

  .right-space {
    margin-right: 2.5rem;
  }

  .active_item {
    color: #AF7756;

    &:hover {
      text-decoration: underline #AF7756;
    }
  }

  .scroll-menu {
    display: flex;
    flex-wrap: wrap;

    overflow-x: auto;
  }

  .scroll-menu::-webkit-scrollbar {
    height: 0px;
  }
}

#breadcrumbApartmentMenu {
  .apartment-breadcrumb {
    //margin-top: 0.938rem;
    overflow-x: auto;
    height: 45px;
    display: flex;

    &::-webkit-scrollbar {
      height: 0px;
    }
  }

  .first-item {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #999999;

    &:hover {
      text-decoration: underline #999999;
    }
  }

  .child-item,
  .child-item-no-link {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #999999;
  }

  .child-item:hover {
    cursor: pointer;
    text-decoration-line: underline;
  }

  .child-active {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3f3f3f;
  }
}

#detailApartmentOverview {
  .apartment-overview {
    &__title {
      margin-top: 1.875rem;
      margin-bottom: 1.75rem;

      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: left;
      color: #222222;
    }

    &__description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #222222;
      p:last-child {
        text-align: center;
        margin-top: 2.5rem;
      }

      img {
        // width: 399px !important;
        // height: 260.5px !important;
        border-radius: 0px !important;
        object-fit: unset !important;
      }
    }
    .container-slider {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 550px;
      max-width: 100%;
      overflow: hidden;
    }
    .slide {
      width: 100%;
      height: 280.46px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
    }
    .slide img {
      object-fit: contain;
      width: 100%;
    }
    .content {
      width: 100%;
      padding: 20px 20px 20px 20px;
      height: 240px;
      background-color: #FFFFFF;
    }
    .project-info {
      margin: 25px 8px 25px 8px;
      width: 100%;
      height: 530px;
      padding: 0;
      .project-name a {
        color: #222222;
      }
    }
    .project-name {
      display: block;
      height: 56px;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 20px !important;
      line-height: 26px !important;
      overflow: hidden !important;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-decoration: unset;
      color: #222222;
  
      &:hover {
        color: black !important;
        transition: all 0.2s ease-in !important;
        text-decoration: none !important;
      }
    }
    .project-location {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
    }
    .apartment-address {
      display: flex;
      flex-direction: row;
    }
    .apartment-address img {
      position: relative;
      max-height: 24px;
      top: 3px;
    }
    .project-bed {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
    }
    .price-and-area {
      min-height: 49px;
    }
    .project-price {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #AF7756;
      .text-through {
        color: #6b6b6b;
        text-decoration: line-through;
      }
    }
    .focus-price {
      font-weight: 700;
    }
    .like-contents {
      position: absolute;
      top: 45px;
      right: 30px;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.3);
      cursor: pointer;
      &:hover {
        background: #90AF3E;;
      }
      svg {
        color: #ffffff;
        fill: #ffffff;
      }
    }
    .like-contents.active {
      background-color: #90AF3E;
    }

    .owl-carousel {
      display: flex;
      max-width: 100%;
      max-height: 100%;
      justify-content: center;
      align-items: center;
      .owl-dots {
        position: absolute;
        top: 85%;
      }
      .owl-dot span {
        background-color: #ffffff;
        width: 8px;
        height: 8px;
        &:hover {
          background-color: hsl(0, 0%, 60%);
        }
      }
      .owl-dot.active span {
        background-color: #AF7756;
      }
      .owl-nav {
        display: flex;
        flex-direction: row;
        top: 45%;
        padding: 0px 16px 0px 16px;
        width: 100%;
        justify-content: space-between;
      }
      .owl-prev {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: hsl(0, 0%, 85%);
        &:hover {
          background-color: hsl(0, 0%, 100%);
        }
      }
      .owl-prev.disabled {
        background-color: hsl(0, 0%, 85%);
      }
      .owl-next {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: hsl(0, 0%, 85%);
        &:hover {
          background-color: hsl(0, 0%, 100%);
        }
      }
      .owl-next.disabled {
        background-color: hsl(0, 0%, 85%);
      }
    }

  }
}

#detailApartmentAmenities {
  .apartment-amenities {
    &__body {
      &--title {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 31px;
        color: #222222;
      }

      .list-services {
        margin: 1.094rem 0 1.094rem 0;

        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #222222;
      }

      .service-disabled {
        // color: #a6a6a6;
        opacity: 0.4;
      }
    }
  }
}

#detailApartmentLocation {
  .apartment-location {
    &__map {
      &--title {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 31px;
        color: #222222;

        margin-bottom: 1rem;
      }
    }
  }
}

#detailApartmentGallery {
  .apartment-gallery {
    &__media {
      width: 399px;
      height: 267px;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    &__btn {
      margin-top: 1.75rem;
      margin-bottom: 4.406rem;
    }
  }
}

.SRLCaptionContainer {
  display: none !important;
}

.SRLThumbnailsContainer {
  display: none !important;
  .SRLThumb:hover {
    opacity: 0.8 !important;
  }
}

#detailApartmentRR {
  .apartment-ratingReview {
    &__title {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 31px;
      color: #222222;

      margin-bottom: 1.5rem;
    }

    &__total {
      margin: unset;

      &--star1 {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: unset !important;

        height: 14.375rem;

        border: 1px solid #999999;
        box-sizing: border-box;
        border-right: unset;

        &__star {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 6.188rem;
          height: 5.563rem;

          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 31px;
          color: #ffffff;

          img {
            margin-top: 1px;
          }
        }

        &__status {
          display: flex;
          align-items: center;
          justify-content: center;

          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 23px;
          color: #222222;

          margin-top: 16px;
        }
      }

      &--star2 {
        display: flex;
        flex-direction: column;

        padding: unset !important;

        border: 1px solid #999999;
        box-sizing: border-box;

        &__list-star {
          margin-top: auto;
          margin-bottom: auto;

          div {
            display: flex;
            align-items: center;
          }

          .text-span {
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            color: #b3b3b3;
          }

          div img {
            vertical-align: unset !important;
          }

          &-bar {
            width: 100%;
            height: 18px;
            background: #e7e7e7;
            padding: unset !important;
          }

          .bar-5 {
            background-color: #1ecd6f;
          }

          .bar-4 {
            background-color: #aed888;
          }

          .bar-3 {
            background-color: #ffd935;
          }

          .bar-2 {
            background-color: #ffb235;
          }

          .bar-1 {
            background-color: #ff8c5a;
          }
        }
      }
    }
  }

  .list-reviews {
    // .user-review-info {
    //   width: 52px;
    //   height: 52px;

    //   // padding-right: unset !important;
    // }

    .user-avatar img {
      width: 52px;
      height: 52px;

      object-fit: cover;

      border-radius: 50%;
    }

    .user-name {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #3f3f3f;

      padding-bottom: 2px;
    }

    .review-date {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #3f3f3f;

      padding-top: 2px;
    }

    .review-star {
      padding-bottom: 2px;
    }

    .text-star {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #3f3f3f;
    }

    .review-content {
      font-family: 'Noto Sans JP';
      word-break: break-word;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #222222;
    }
  }

  .rating-review-paginate {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        padding: 0 3px 0;
      }

      li:nth-child(1) {
        padding-right: 20px;
      }

      li:last-child {
        padding-left: 20px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .apartment-ratingReview__total--star1 {
      border-right: 1px solid #999999;
      border-bottom: unset;
    }
  }
}

.is-error {
  border-color: #dc3545;
}

.detail-apartment-title {
  margin-top: 2.875rem;
  margin-bottom: 1.5rem;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #222222;
}
.detail-photo-title {
  margin-top: 2.875rem;
  margin-bottom: 1.5rem;

  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #222222;
}

.btn-booknow {
  max-width: 361px;
  width: 100%;
  height: 44px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;

  color: #ffffff;
  background-color: #AF7756;
  border: unset;
  border-radius: unset;

  &:hover {
    opacity: 0.8;
  }
}

.btn-booknow-a {
  width: 361px;
}

.mg-top-bottom {
  margin-top: 1.625rem;
  margin-bottom: 4.406rem;
}

.SRLOpened {
  // height: 100vh;
}

.css-10yc0wa {
  z-index: 999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-1yn0gya {
  height: 80% !important;
}

.SRLStage {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 72%;
  height: 95%;
  background-color: #fff;
  top: 3%;
}
.SRLElementContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear-margin-row {
  margin: unset;
}

#offers-detail {
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: #131313;
  }
  .content {
    font-size: 16px;
    line-height: 21px;
    color: #131313;
  }
  .btn-booknow {
    border: none;
    background: #AF7756;;
    width: 361px;
    color: #fff;
    height: 44px;
  }
}
.SRLElementWrapper {
  img {
    width: 800px !important;
    max-height: 500px !important;
  }
}
@media (min-width: 786px) {
  .contact-us-modal {
    max-width: 800px !important;
  }

}