* {
  font-family: 'Noto Sans JP';
}
#forgotPassword {
  .card {
    height: 100%;
    align-items: center;
    background-color: #e5e5e5;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
  }
  .reset {
    height: 100%;
    width: 487px;
    background-color: #ffffff;
    margin-top: 137px;
    margin-bottom: 70px;
    padding: 40px 63px 40px 63px;
  }
  .password {
    border-radius: 1px;
    background-color: #ffffff;
    height: 44px;
  }
  .save-button {
    width: 100%;
    height: 44px;
    font-size: 16px;
    color: #ffffff;
    border: none;
    background-color: #AF7756;
    border-color: #AF7756;
    border-radius: 1px;
    font-weight: bold;
    &:hover {
      opacity: 0.7;
    }
  }
  .reset-input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #999999;
    box-sizing: border-box;
    height: 44px;
    font-style: normal;
  }
  .reset-input-err {
    background: #ffffff;
    border: 1px solid #b64b4b;
    box-sizing: border-box;
    border-radius: 1px;
    height: 44px;
    font-style: normal;
  }
  .form-group label {
    font-size: 16px;
    font-weight: normal;
    line-height: 21px;
  }
  .form {
    margin-top: -20px;
  }

  @media only screen and (min-width: 425px) and (max-width: 576px) {
    .reset {
      width: 400px;
    }
  }
  @media only screen and (max-width: 425px) {
    .reset-password-form {
      .card {
        min-height: 516px;
      }
      .reset {
        padding: 1.5rem;
        width: 300px;
        height: 414px;
        margin-top: 32px;
      }
      .card-body {
        padding: 32px;
      }
    }
  }
}
