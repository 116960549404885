.servicedOffice {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 145px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.servicedOfficeHide {
  position: relative;
}

.contact-form {
  background-color: #ffffff;
  padding: 50px 15px !important;
}
#servicedOfficeMenu {
  background-color: #fafafa;
  .serviced-office-menu-item {
    display: inline-block;
    color: #000;
    // padding-bottom: 16px;

    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    cursor: pointer;

    &:hover {
      text-decoration: underline #000;
    }
  }

  .right-space {
    margin-right: 2.5rem;
  }

  .types-menu-hover:hover .dropdown-menu {
    display: block;
  }

  .active_item {
    color: #af7756 !important;

    &:hover {
      text-decoration: underline #af7756;
    }
  }

  .dropdown-menu {
    display: none;
    width: 185px;
    height: 152px;
    margin: 0;

    position: absolute;
    top: 33px;
    left: 8%;

    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: unset;

    li {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #131313;

      padding: 6px 16px 6px 16px;

      a {
        color: #131313;
        &:hover {
          color: $color_red_1 !important;
          transition: all 0.2s ease-in !important;
          text-decoration: none !important;
        }
      }
    }
  }

  .dropdown-menu-jp {
    left: 5%;
  }
}

#ourOfficeBanner {
  .our-office-banner {
    position: relative;
    width: 100%;
    height: 351px;

    &__info {
      position: absolute;
      top: 0;

      width: 100%;
      height: 100%;

      z-index: 3;

      &--title {
        font-family: 'SVN-Quiche Sans';
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: #ffffff;
      }

      &--description {
        display: flex;

        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      max-height: 351px;

      object-fit: cover;
    }

    &__color-overlay {
      width: 100%;
      height: 100%;

      background: #000;
      opacity: 0.4;

      position: absolute;
      top: 0;
      z-index: 2;
    }
  }
}

#typesOfficeBanner {
  .types-office-banner {
    position: relative;
    width: 100%;
    height: 351px;

    &__info {
      position: absolute;
      top: 0;

      width: 100%;
      height: 100%;

      z-index: 3;

      &--title {
        font-family: 'SVN-Quiche Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 49px;
        color: #ffffff;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      max-height: 351px;

      object-fit: cover;
    }

    &__color-overlay {
      width: 100%;
      height: 100%;

      background: #000;
      opacity: 0.4;

      position: absolute;
      top: 0;
      z-index: 2;
    }
  }
}

#ourOfficeBanner,
#typesOfficeBanner {
  .owl-nav {
    position: unset;
    top: unset;
    left: unset;

    .owl-prev {
      position: absolute;
      top: 43%;
      left: 5%;

      &:hover {
        background-color: unset;
        color: unset;
        transition: unset;

        svg {
          // opacity: 0.8;
          fill: #fff;
        }
      }
    }

    .owl-next {
      position: absolute;
      top: 43%;
      right: 5%;

      &:hover {
        background-color: unset;
        color: unset;
        transition: unset;

        svg {
          // opacity: 0.8;
          fill: #fff;
        }
      }
    }
  }

  .owl-dots {
    bottom: 40px;

    .active {
      span {
        background-color: #af7756 !important;
      }
    }
  }

  .owl-dot {
    span {
      width: 12px;
      height: 12px;
    }
  }
}

#servicedOfficeOverview {
  .overview-desc {
    padding-top: 46px;
  }
  .image-show-overview {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .overview-image {
    padding-top: 51px;

    img {
      width: 100%;
    }
  }

  .video-responsive {
    padding: 0 0 70px 0;
  }
  .content-img::-webkit-scrollbar {
    height: 40px;
    width: 6px;
    border-radius: 8px;
    background-color: #c1c1c1;
  }

  .content-img::-webkit-scrollbar-thumb {
    background: #af7756;
  }

  .welcome {
    height: fit-content;
    width: 100%;
    .image {
      z-index: 1;
    }
    img {
      height: 459px;
      width: 100%;
    }
    .content-img {
      position: relative;
      left: 0;
      right: 0;
      z-index: 8;
      bottom: 100px;
      background-color: #ffffff;
      width: 90%;
      padding: 26px 48px;
      overflow: auto;
      &__title {
        font-family: 'SVN-Quiche Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        color: #131313;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 0 !important;
      }
    }
  }

  .reasons {
    height: fit-content;
    width: 100%;
    .image {
      z-index: 1;
    }
    img {
      height: 459px;
      width: 100%;
    }
    .content-img {
      position: relative;
      left: 10%;
      right: 0;
      z-index: 8;
      bottom: 100px;
      background-color: #ffffff;
      width: 90%;
      padding: 26px 48px;
      overflow: auto;
      &__title {
        font-family: 'SVN-Quiche Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        color: #131313;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 0 !important;
      }
    }
  }

  .start-with-us {
    // min-height: 510px;
    width: 100%;
    position: relative;
    .image {
      z-index: 1;
      height: 510px;
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
    }
    .why_title {
      margin: auto;
    }
    .content-img {
      position: relative;
      left: 0;
      right: 0;
      z-index: 8;
      bottom: 0;
      min-height: 510px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      height: -webkit-fill-available;
      background-image: url('../../images/web-isn/overview_office.png');
      &:before {
        position: absolute;
        content: '';
        background-color: #000;
        opacity: 0.5;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
      }
      .content {
        z-index: 8;
        background-color: #ffffff;
        height: fit-content;
        width: 90%;
        margin: 40px 60px 40px 0;
        padding: 30px;
        overflow: auto;
        display: block;
        &__index {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 700;
          color: #af7756;
          font-size: 16px;
          line-height: 23px;
          margin-right: 20px;
        }
        &__title {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 700;
          color: #222222;
          font-size: 16px;
          line-height: 23px;
        }
      }
      .title {
        font-family: 'SVN-Quiche Sans';
        color: #ffffff;
        font-size: 32px;
        margin-bottom: 30px;
      }
      .title-content {
        font-family: 'Noto Sans JP';
        color: #ffffff;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}

#typesServiced {
  margin-top: 70px;
  .list-service-show {
    min-height: 445px;
    max-height: 1000px;
    .content-service {
      background-color: #ffffff;
    }
    .img-servide {
      max-height: 100%;
      min-height: 100%;
      img {
        max-height: 100%;
        min-height: 100%;
      }
    }
    .name-content {
      font-size: 16px;
      img {
        margin-right: 5px;
      }
    }
  }

  .looking-for {
    background-color: #e5ddd8;
    min-height: auto;
    max-height: 315px;
    width: 100%;
    margin-right: 15px;
    margin-left: 15px;
    position: relative;
    overflow: hidden;
    &:before {
      background-image: url('../../../assets/images/web-isn/you_are_looking.png');
      position: absolute;
      content: '';
      opacity: 0.5;
      height: 245.29px;
      width: 245.93px;
      background-size: cover;
      right: 0;
      bottom: -65px;
      transform: rotate(10deg);
    }
    @media (max-width: 768px) {
      &:before {
        display: none;
      }
    }
  }

  .related-news {
    position: relative;

    .title {
      padding: 15px 0 40px 0;
      font-family: 'SVN-Quiche Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 31px;
      color: #222222;
    }
    .content {
      display: flex;
      margin-top: 20px;
      .img-new {
        width: 120px;
        height: 120px;
        img {
          width: 120px;
          height: 120px;
        }
      }
      .text-new {
        display: block;
        margin-left: 20px;
        &__info {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 28.96px;
          color: #222222;
        }
        &__time {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23.17px;
          color: #999999;
        }
      }
    }
  }
  .desc {
    padding: 46px 0 1px 0;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #131313;
  }

  .desc {
    padding: 47px 0 0 0;
  }

  .title {
    padding: 31px 0 23px 15px;
    font-family: 'SVN-Quiche Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 35px;
    color: #131313;

    &-red {
      color: #af7756;
    }
  }

  .list-serviced {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #131313;
    .click-here {
      padding: 10px;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #131313;

      a {
        color: #af7756;
        cursor: pointer;
      }
    }
  }

  .serviced-contents {
    .title {
      padding: 31px 0 23px 0;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 31px;
      color: #131313;

      &-red {
        color: #af7756;
      }
    }

    &__table {
      &--head {
        background-color: #d1ddee;

        .head-left {
          display: flex;
          justify-content: center;

          padding: 14.5px 0px;
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 21px;
          color: #000000;
          border-right: 1px solid #ffffff;
        }

        .head-right {
          border-left: 1px solid #ffffff;
        }
      }

      &--body {
        .body-contents {
          &-left {
            padding: 14.5px 0px 14.5px 2.625rem;
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            color: #222222;
            border-right: 1px solid #ffffff;
          }

          &-right {
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid #ffffff;

            .right-circle {
              width: 16px;
              height: 16px;
              border: 1px solid #222222;
              box-sizing: border-box;
              border-radius: 50%;
            }
          }
        }

        .backgr-white {
          background-color: #ffffff;
        }

        .backgr-blue {
          background-color: #daedf3;
        }
      }

      &--footer {
        margin-bottom: 17px;
        background-color: #92ccdc;

        .footer-left {
          display: flex;
          justify-content: center;

          padding: 39.5px 0px;
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 21px;
          color: #222222;
          border-right: 1px solid #ffffff;
        }

        .footer-right {
          display: flex;
          justify-content: center;

          padding: 39.5px 0px;
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 21px;
          color: #222222;
          border-left: 1px solid #ffffff;
        }
      }
    }
  }

  .scroll-menu {
    display: flex;
    flex-wrap: wrap;

    overflow-x: auto;
  }

  .scroll-menu::-webkit-scrollbar {
    height: 0px;
  }

  .room-type {
    width: 85%;
    margin: 0 auto;
    padding-bottom: 18px;

    &__header {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      background-color: #5a9ad4;

      div {
        padding: 16px 0px 16px 16px;
      }

      div:nth-child(2) {
        padding: 16px 8px;
      }
    }

    &__body {
      .body-type {
        background-color: #5a9ad4;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 40px;
        color: #ffffff;
      }

      .furniture-image {
        padding: 0 14px 0 18px;
      }

      .furniture-content {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: #222222;
      }

      .backgr-content {
        background-color: #e9eef6;
      }

      .body-text {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 40px;
        color: #222222;
      }
    }
  }

  .other-choice {
    position: relative;

    .title {
      padding: 15px 0 40px 0;
      font-family: 'SVN-Quiche Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 31px;
      color: #222222;
    }

    &__info {
      height: 267px;
      background-color: rgba(0, 0, 0, 0.4);

      &--title {
        font-family: 'SVN-Quiche Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        color: #ffffff;
        z-index: 3;
      }

      &:hover {
        background-color: rgba(59, 59, 59, 0.123);
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      max-height: 351px;

      object-fit: cover;
    }

    a {
      display: unset;
      text-decoration: unset;
      color: unset;
    }
  }

  .product-image {
    &:nth-child(2) {
      position: absolute;
      top: 90px;
    }
  }
  .text-title {
    font-family: 'SVN-Quiche Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    color: #222222;
  }
  .text-description {
    font-family: 'Noto Sans JP';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: #777777;
    text-align: left !important;
  }

  .card-info {
    background-color: $color_red_1;
    color: $color_white_2;
    border-radius: 10px;
    width: 210px;
    padding-top: 30px;
    padding-bottom: 30px;
    .text-bolder {
      color: $color_white_2;
      font-size: 26px;
      font-weight: 700;
      font-family: 'Noto Sans JP';
    }
  }
  .list-advantages {
    list-style-image: url('../../../assets/images/element-list-style.svg');
    li {
      line-height: 32px;
    }
  }
  .card-feature {
    height: 100%;
    background-color: $color_white_2;
    padding: 30px;
    max-height: auto;
    min-height: 365px;
    text-align: center;
    .titte-feature {
      font-weight: 700;
      font-family: 'SVN-Quiche Sans';
      text-align: left !important;
    }
    span {
      height: 150px;
    }
  }
}
.link-yellow-bold {
  color: #af7756;
  font-weight: 700;
  &:hover {
    color: #af7756;
  }
}

#servicedOfficeLocation {
  .location-content {
    padding: 46px 0 70px 0;

    .content-right {
      padding-left: 1.875rem;
    }

    .location-image {
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

#servicedOfficeGallery {
  .gallery-title-1 {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    color: #131313;

    padding: 46px 0 24px 0;
  }

  .gallery-title-2 {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    color: #131313;

    padding-bottom: 24px;
  }

  .our-office-gallery {
    &__media {
      width: 399px;
      height: 267px;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .video-responsive {
    padding: 0px 0 71px 0;
  }
}

.service-video {
  width: 100%;
  height: 715px;
}

@media only screen and (max-width: 1200px) {
  .room-type {
    width: 1000px !important;
  }
}

@media only screen and (max-width: 768px) {
  .welcome {
    .content-img {
      padding: 5px 10px !important;
    }
  }
  .reasons {
    .content-img {
      padding: 5px 10px !important;
    }
  }

  .start-with-us {
    .content-img {
      .content {
        width: 100% !important;
        margin: 0 15px 15px 15px !important;
      }
    }
    .why_title {
      margin: 15px !important;
    }
  }
}

@media only screen and (max-width: 576px) {
  .service-video {
    width: 100%;
    height: 200px;
  }
}
