#loadingPage {
  .loader {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000010 !important;
    background-color: #f8f8f8ad;
  }

  .spinner-border {
    position: fixed;
    top: 50%;
    left: 48.2%;
  }
}
