$color_black: #000;
$color_black_1: rgba(19, 19, 19, 0.5);
$color_black_2: #131313;
$color_black_3: rgba(19, 19, 19, 0.3);
$color_black_4: rgba(19, 19, 19, 0.12);
$color_black_5: rgba(19, 19, 19, 0);
$color_black_6: rgba(19, 19, 19, 0.7);
$color_black_7: rgba(19, 19, 19, 0.87);
$color_black_8: rgba(19, 19, 19, 0.8);
$color_black_9: rgba(19, 19, 19, 0.6);
$color_black_10: rgba(0, 0, 0, 0.125);
$color_black_11: rgba(19, 19, 19, 0.4);
$color_black_13: rgba(19, 19, 19, 0.38);
$color_black_14: rgba(0, 0, 0, 0.3);
$color_black_15: rgba(0, 0, 0, 0.7);
$color_black_16: rgba(0, 0, 0, 0.95);
$color_black_17: rgba(249, 249, 249, 0);
$color_black_18: #00000094;

$color_red_1:#AF7756;
$color_red_2: #f00;
$color_red_3: #900;
$color_red_4: #d23c3c;

$color_white_1: #fff;
$color_white_2: #ffffff;

$color_gray_1: #f5f5f5;
$color_gray_2: #666;
$color_gray_3: #999;
$color_gray_4: #bcbcbc;
$color_gray_5: #cecece;
$color_gray_6: #888888;
$color_gray_7: #777;
$color_gray_8: rgba(225, 225, 225, 0.2);
$color_gray_9: rgba(255, 255, 255, 0.15);
$color_gray_10: rgba(225, 225, 225, 0.8);
$color_gray_11: rgba(255, 255, 255, 0.95);
$color_gray_12: #7f7f7f;
$color_gray_13: #555;
$color_gray_14: #989898;
$color_gray_15: #e0e0e0;
$color_gray_16: #dbdbdb;
$color_gray_17: #717171;
$color_gray_18: #626262;
$color_gray_19: #e9ecef;
$color_gray_20: rgba(19, 19, 19, 0.6);
$color_gray_21: #d8d8d8;
$color_gray_22: #dee2e6;
$color_gray_23: #9a9a9a;
$color_gray_24: #efefef;
$color_gray_25: rgba(255, 255, 255, 0.7);

$color_orange: #fdc09d;
$color_orange_1: #f79433;
$color_orange_2: #f8b575;
$color_orange_3: #fcd7b4;

$color-blue_1: #3a90d8;
$color-blue_2: #0d4e80;
$color-blue_3: #648eae;

$color-green_1: #4c924d;
$color_brown_1: #af7756;