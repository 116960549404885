.detail-project-font-size-24 {
    font-size: 24px !important;
}
.height-290px{
 height: 290px !important;
}

.text-decoration-color-black-hover:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: black;
}

.project-box-title-hover h4:hover {
    // -webkit-text-decoration-line: underline;
    // text-decoration-line: underline;
    color: #AF7756;
}

.beadcrumb-padding-left-12px {
    padding-left: 12px;
}

.content-padding-top {
    padding-top: 46px;
}

.blog-categories-margin-top {
    margin-top: 40px;
}

.text-transform-none {
    text-transform: none;
}

.Toastify__toast-container--top-right {
    margin-top: 130px;
}
.rating-form {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 4px;
    .rating {
        & span {
            margin-left: 5px;
        }
    }
    .rating-title {
        font-size: 24px;
    }
    .rating-number {
        color: #3F3F3F;
        font-size: 32px;
    }
}

.project-list-search {
    .nav-top.owl-theme .owl-nav {
    position: relative !important;   
    }

    .nav-top.owl-theme .owl-nav .owl-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0px !important;
        border-radius: 0px !important;
        background: rgba(0, 0, 0, 0.7);
        height: 70px;
        width: 40px;
        top: -250px;
    }

    .nav-top.owl-theme .owl-nav .owl-prev:not(.disabled):hover {
        background-color: black;
    }

    .nav-top.owl-theme .owl-nav .owl-next {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px !important;
        border-radius: 0px !important;
        background: rgba(0, 0, 0, 0.7);
        height: 70px;
        width: 40px;
        top: -250px;

    }

    .nav-top.owl-theme .owl-nav .owl-next:not(.disabled):hover {
        background-color: black;
    }
}


.width-fit-content {
    width: fit-content !important;
}

.link-helps-active{
    color: #AF7756;
}

.link-helps-active:hover {
    color: #AF7756;
}

.border-radius-none {
    border-radius: none !important;
}

.btn-go-gome {
    width: 257px;
}

.faqs-collapse {
    display: flex;
    cursor: pointer;
}

.issue-type-checked {
    accent-color: #AF7756;
    margin-right: 18px;
}

.faqs-collapse:hover .faqs-collapse-title {
        color: #AF7756;
        font-weight: 500;
        transition: all 0.2s ease-in;
}

.faqs-collapse.actived .faqs-collapse-title {
    color: #AF7756;
    font-weight: 500;
}

.position-icon-search-property {
    position: absolute;
    top: 11px;
    left: 12px;
    cursor: text;
}

.message-error-search-propetyties {
    font-size: 16px;
    color: #990000;
}

.padding-left-input-propery {
    padding-left: 48px;
}

.review-tags {
    background-color: #DADADA;
    padding: 2px 5px 2px 5px;;
    height: fit-content;
    color: #777777;
    font-size: 14px;
}

.rating-margin-bottom {
    margin-bottom: 3px;
}

.width-infor-rating {
    width: 140px;
}

.section-padding-top {
    padding-top: 24px;
}

.read-more-less--more{
    color: #007bff !important;
}

.read-more-less--less {
    color: #007bff !important;

}

.policy-title {
    font-size: 35px;
    color: #AF7756;
    font-weight: bold;
}

.policy-date {
    font-size: 18px;
    font-weight: bold;
}

.faqs-collapse-title {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: #131313;
}

.text-truncate-address {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
}

@media screen and (max-width: 2100px) {
    .text-truncate-breadcrumb-name {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block !important;
    }

    .text-truncate-banner-name {
        max-width: 1100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .text-truncate-box-title-name {
        color: #222;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: break-spaces;
    }

}


.popup-search {
    max-width: 1170px !important;

    .search-tab-margin {
        margin-top: -48px;
    }

    .modal-content {
        margin-top: 30%;
    }
}

.modal-login {
    .modal-content {
        width: 487px;
        justify-content: center;
        align-items: center;
    }
}

.forgot-modal {
    .modal-content {
        width: 487px;
        justify-content: center;
        align-items: center;
    }
}

.find-property-map {
    .box-tooltip-map-detail {
        width: 250px;
        .btn-close-tooltip {
            width: fit-content;
            position: absolute;
            right: 6px;
            top: 1px;
            cursor: pointer;
        }
        & h3 {
            font-size: 22px;
            cursor: pointer;
            color: #222;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: break-spaces;
            &:hover {
                color: #AF7756;
            }
        }
        & img {
            cursor: pointer;            
        }
        .location-address {
            cursor: pointer;
        }
    }
}
.supports {
    .icon-search img {
    border-radius: 9999px;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 2;
    }

    @keyframes cycle-colors {
    0% { border-color: hsl(22, 36%, 51%); }
    50% { border-color: hsl(22, 36%, 60%); }
    100% { border-color: hsl(22, 36%, 80%); }
    }

    @keyframes pulse {
    to {
        opacity: 0;
        transform: scale(1);
    }
    }

    .icon-search::before,
    .icon-search::after {
    animation: pulse 2s linear infinite;
    border: #AF7756 solid 8px;
    border-radius: 9999px;
    box-sizing: border-box;
    content: ' ';
    height: 140%;
    left: -20%;
    opacity: .6;
    position: absolute;
    top: -20%;
    transform: scale(0.714);
    width: 140%;
    z-index: 1;
    }

    .icon-search::after {
    animation-delay: 1s;
    }

    // .icon-search:hover img {
    // content: url("../../images/icon/icon-search.svg");
    // }

    .icon-search:hover::before,
    .icon-search:hover::after {
    animation: pulse 1s linear infinite, cycle-colors 6s linear infinite;
    }

    .icon-search:hover::after {
    animation-delay: .5s;
    }
}

.for-owner-lable-price {
    position: absolute;
    top: 8px;
    right: 5px;
}