span.error {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #dc3545;

  padding-top: 16px;
}

.input-error {
  border: 1px solid #dc3545;
  box-sizing: border-box;
}

.required-star {
  color: #b3091b;
}

#paymentPage {
  // Class commons
  .font-red {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #dc3545;
  }

  .font-green {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #35a734;
  }

  .font-green_normal {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #35a734;
  }

  .font-blue {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #2196f3;
    word-break: break-all;
  }

  .time-check-in-out {
    color: #777777;
  }

  .form-control-date-picker {
    padding-left: 47px;
  }

  .button-confirm-page {
    padding: 50px 0;

    &__back {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 361px;
      height: 44px;
      background: #ffffff;
      border-radius: unset;
      margin-right: 31px;

      font-weight: bold;
      color: #af7756;
      border: 1px solid#AF7756;

      &:hover {
        opacity: 0.8;
      }
    }

    &__continue {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 361px;
      height: 44px;
      background: #af7756;
      border-radius: unset;

      font-weight: bold;
      color: #ffffff;
      border: unset;

      &:hover {
        opacity: 0.8;
      }
    }

    button:disabled,
    button[disabled] {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .payment-details__font-bold {
    font-weight: bold;
  }

  .payment-page {
    margin-top: 51px;
    margin-bottom: 51px;
    background-color: #ffffff;

    .payment-date-picker {
      position: relative;

      .calendar-img {
        position: absolute;
        top: 11px;
        left: 28px;
      }

      &__input {
        padding-left: 45px;
      }
    }

    .react-datepicker__triangle {
      left: -50px !important;
    }

    .Stepper {
      position: relative;
      display: table;
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
    }

    .Stepper__step {
      position: relative;
      display: table-cell;
      text-align: center;
      padding: 2rem 0.5rem 0.5rem 0.5rem;
    }

    .Stepper__step-2 {
      position: relative;
      display: table-cell;
      text-align: center;
      padding: 2rem 0.5rem 0.5rem 0.5rem;
    }

    .Stepper__step-3 {
      position: relative;
      display: table-cell;
      text-align: center;
      padding: 2rem 0.5rem 0.5rem 0.5rem;
    }

    .Stepper__indicator {
      position: relative;
      display: block;
      z-index: 2;
    }

    .Stepper__label {
      position: relative;
      display: block;
      margin: 18px 0 0 0;
      font-size: 16px;
      line-height: 21px;
      color: #af7756;
      z-index: 2;
    }

    .Stepper__label--not-active {
      position: relative;
      display: block;
      margin: 28px 0 0 0;
      font-size: 16px;
      line-height: 21px;
      color: #222222;
      z-index: 2;
    }

    .Stepper__info {
      position: relative;
      display: inline-block;
      width: 110px;
      height: 110px;
      border: 10px solid #d6ae96;
      border-radius: 50%;

      background-color: #af7756;
      font-size: 34px;
      line-height: 5.3rem;
      text-align: center;
      color: #fff;
      z-index: 2;
    }

    .Stepper__info--img {
      line-height: 4.8rem;
    }

    .Stepper__info--not-active {
      position: relative;
      display: inline-block;
      width: 90px;
      height: 90px;
      border: 3px solid #dddddd;
      border-radius: 50%;

      background-color: #eeeeee;
      font-size: 34px;
      line-height: 5.3rem;
      text-align: center;
      color: #6b6b6b;
      z-index: 2;
    }

    .Stepper__panel {
      display: none;
    }

    .Stepper .Stepper__step:after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 5.3rem;
      width: 100%;
      height: 4px;
      background-color: #eeeeee;
      z-index: 1;
    }

    .Stepper .Stepper__step-2:after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 5.3rem;
      width: 100%;
      height: 4px;
      background-color: #af7756;
      z-index: 1;
    }

    .Stepper .Stepper__step-3:after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 5.3rem;
      width: 100%;
      height: 4px;
      background-color: #af7756;
      z-index: 1;
    }

    .Stepper .Stepper__step-2:nth-child(2):after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 5.3rem;
      width: 100%;
      height: 4px;
      background-color: #eeeeee;
      z-index: 1;
    }

    .Stepper .Stepper__step:last-child:after {
      display: none;
    }

    .Stepper .Stepper__step-2:last-child:after {
      display: none;
    }

    .Stepper .Stepper__step-3:last-child:after {
      display: none;
    }

    .Stepper--inline .Stepper__indicator,
    .Stepper--inline .Stepper__label {
      display: inline-block;
      vertical-align: inherit;
    }
    .Stepper--inline .Stepper__label {
      text-align: left;
      padding: 0 0.5rem;
      background: #fff;
    }

    .Stepper--inline.Stepper--bottom .Stepper__label {
      vertical-align: middle;
    }
    .Stepper--inline.Stepper--bottom .Stepper__step:after {
      top: auto;
      bottom: 1.75rem;
    }

    .Stepper--vertical .Stepper__step {
      display: block;
      text-align: left;
    }

    .Stepper--vertical .Stepper__label {
      padding-left: 1rem;
    }

    .Stepper--vertical .Stepper__indicator,
    .Stepper--vertical .Stepper__label {
      display: table-cell;
    }

    .Stepper--vertical .Stepper__panel {
      margin-left: 3.5rem;
    }

    .Stepper--vertical .Stepper__step:after {
      content: ' ';
      position: absolute;
      left: 1.75rem;
      top: 2.5rem;
      bottom: -0.5rem;
      width: 0;
      height: auto;
      border-left: 0.125rem solid #e3e8ec;
    }

    .Stepper .Stepper__step.is-complete .Stepper__info {
      border-color: #a6b6c3;
      background-color: #a6b6c3;
    }
    .Stepper .Stepper__step.is-complete .Stepper__label {
      color: #a6b6c3;
    }

    .Stepper .Stepper__step.is-active .Stepper__info {
      border-color: #627c90;
      background-color: #627c90;
    }
    .Stepper .Stepper__step.is-active .Stepper__label {
      color: #627c90;
    }

    .Stepper .Stepper__step.is-warning .Stepper__info {
      border-color: #f1c40f;
      background-color: #f1c40f;
    }
    .Stepper .Stepper__step.is-warning .Stepper__label {
      color: #f1c40f;
    }

    .Stepper .Stepper__step.is-error .Stepper__info {
      border-color: #e95a4b;
      background-color: #e95a4b;
    }
    .Stepper .Stepper__step.is-error .Stepper__label {
      color: #e95a4b;
    }

    .payment-form {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: normal;
      padding-left: 32px;
      padding-right: 25px;

      &__title {
        font-size: 24px;
        line-height: 31px;
        color: #af7756;

        padding: 24px 0 16px 0;
      }

      &__desc {
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: #131313;

        padding-bottom: 16px;
      }

      &__captcha {
        margin: 26px 0 0 17px;
      }

      &__vat-checkbox {
        display: block;
        position: relative;
        padding-left: 27px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 3px;
          left: 0;
          height: 17px;
          width: 17px;
          background-color: #eee;
        }

        &:hover input ~ .checkmark {
          background-color: #ccc;
        }

        & input:checked ~ .checkmark {
          background-color: #af7756;
        }

        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        & input:checked ~ .checkmark:after {
          display: block;
        }

        & .checkmark:after {
          left: 5px;
          top: 2px;
          width: 7px;
          height: 11px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .form-group {
        font-size: 16px;
        line-height: 21px;
        margin: 0 0 24px 0;

        &__label {
          font-weight: bold;
        }

        label {
          margin: 0 0 16px 0;
        }

        &__paymentInfo {
          font-weight: bold;
          color: #131313;

          margin: 8px 0 0 0;
        }
      }
    }

    .payment-term {
      max-height: 400px;
      overflow-x: hidden;
      overflow-y: scroll;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      padding: 15px;

      &-condition-title {
        font-size: 24px;
        // font-weight: bold;
        color: #000000;

        margin-top: 50px;
        margin-bottom: 5px;
      }

      &_title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
      }

      &_box {
        margin-bottom: 10px;
      }

      &_table {
        margin-top: 10px;

        table,
        th,
        td {
          border: 1px solid black;
          border-collapse: collapse;
        }

        th,
        td {
          font-size: 16px;
          color: #000000;
          font-weight: normal;
          padding: 10px;
        }
      }
    }

    .payment-step1-right {
      display: contents;
    }

    .payment-step1-mobile {
      display: none;
    }

    @media (max-width: 991.98px) {
      .payment-step1-right {
        display: none;
      }
      .payment-step1-mobile {
        display: contents;
      }
    }

    @media print {
      .payment-detail {
        padding: 0 0 0 32px;
      }
    }

    .payment-detail {
      padding: 71px 0 0 32px;

      &__title {
        font-size: 24px;
        line-height: 31px;
        color: #af7756;
      }

      &__desc {
        margin-top: 23px;

        span {
          font-family: Noto Sans JP;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          color: #222222;

          margin-right: 10px;
        }
      }

      &__paymentType {
        font-weight: bold;
        color: #131313;

        margin: 24px 0 16px 0;
      }

      &__selectPayment {
        width: 267px;
        height: 44px;
        background: #ffffff;
        border: 1px solid #999999;
        box-sizing: border-box;

        &:hover {
          cursor: pointer;
        }
      }

      select {
        font-size: 16px;
        line-height: 21px;
        color: #303337;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url(../../images/web-isn/select-down-arrow.svg);
        background-repeat: no-repeat;
        background-position-x: 94%;
        background-position-y: 50%;
        // border: 1px solid #dfdfdf;
        // border-radius: 2px;
        padding: 10px;
      }

      .payment-form__captcha {
        margin: 50px 0;
      }
    }

    .booking-info {
      padding: 71px 29px 0 10px;

      &__content {
        &--title {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 31px;
          color: #ffffff;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          white-space: break-spaces;
          width: 100%;
          padding: 7px 23px 7px 23px;
          background: #af7756;
        }

        &--booking {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 21px;
          color: #222222;

          position: relative;
          padding: 23px 8px;
          background: #f5f5f5;

          .draw-border {
            width: 100%;
            padding-top: 23px;
            border-bottom: 1px solid #999999;
          }

          .col-5 {
            padding-right: 11.5px;
          }

          .col-7 {
            padding-left: 11.5px;
          }

          &_img {
            width: 100%;
            height: 148px;
          }

          &_title {
            margin-bottom: 20px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: break-spaces;
          }

          &_apartment-info {
            .pd-20px {
              padding-bottom: 10px;
            }

            .map-pin {
              width: 20px;
              margin-right: 8px;
            }

            .bedrooms {
              margin-right: 8px;
            }

            .price-per-day {
              font-weight: bold;
              color: #af7756;

              .text-night {
                font-weight: normal;
              }
            }

            .area {
              margin-right: 8px;
            }
          }

          .check-date {
            padding-top: 23px;

            &_title {
              font-weight: bold;
              padding-bottom: 12px;
            }

            &_info {
              font-weight: 500;
              color: #af7756;
            }
          }

          .payment-details {
            padding-top: 23px;

            &__pd-bottom {
              padding-bottom: 23px;
            }
          }
        }
      }
    }

    .bank-transfer {
      &__contents {
        width: 267px;
        height: 44px;
        color: #999999;
        padding: 10px;
        border: 1px solid #999999;
      }

      &__info {
        display: flex;
        border: 1px solid #999999;

        &-left {
          padding: 32px 24px 0 32px;
        }

        &-right {
          line-height: 21px;
          padding: 32px 0 41px 0;
        }
      }

      &__copy {
        font-weight: bold;
        color: #2196f3;

        margin-left: 15px;

        &:hover {
          color: #007adf;
          cursor: pointer;
        }
      }

      .checkbox-transferred {
        display: table-cell;

        &__checkmark {
          top: 12px;
        }
      }
    }
  }

  .confirmation-page {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #131313;

    padding: 0 32px;

    &__title {
      font-size: 24px;
      line-height: 31px;
      color: #af7756;
      padding: 71px 0 16px 0;
    }

    @media print {
      &__title {
        padding: 0 0 16px 0;
      }
    }

    &__header {
      display: none;
    }

    &__header2 {
      clear: both;
    }

    &__hotline {
      float: right;
    }

    &__email {
      float: right;
      margin-right: 5px;
    }

    &__company-name {
      float: left;
    }

    @media print {
      &__title {
        padding: 0 0 16px 0;
      }

      &__header {
        display: block;
      }
    }

    &__booking-detail {
      &_title {
        font-size: 24px;
        line-height: 31px;
        color: #131313;

        padding: 24px 0 20px 0;
      }

      &_table {
        border: 1px solid #a9a9a9;

        &--left-contents {
          padding: 17px 16px 0 15px;
          background-color: #f5f5f5;
        }

        &--right-contents {
          padding: 0 32px;
        }

        &--img {
          width: 100%;
        }

        @media print {
          &--img {
            display: none;
          }
        }

        &--apartment-name {
          padding: 16px 0 17px 0;
        }

        &--location {
          display: flex;
          padding-bottom: 16px;

          &_right {
            padding-left: 12.5px;
          }

          &_address {
            padding-bottom: 8px;
          }

          &_viewmap {
            display: inline-block;
            font-size: 14px;
            line-height: 18px;
            text-decoration-line: underline;
            color: #2196f3;

            &:hover {
              color: #007adf;
              cursor: pointer;
            }
          }
        }

        &--hotline {
          display: flex;
          align-items: center;
          padding-bottom: 20px;

          img {
            padding-right: 8px;
          }
        }

        &--bedrooms {
          display: flex;
          align-items: center;
          padding-bottom: 22px;

          img {
            padding-right: 8px;
          }
        }

        &--area {
          display: flex;
          align-items: center;
          padding-bottom: 22px;

          img {
            padding-right: 8px;
          }
        }

        &--print {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          margin-bottom: 23px;

          img {
            padding-right: 12px;
          }

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }

        &--manage-booking {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          margin-bottom: 23px;

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }

        &--border-bottom {
          border-bottom: 1px solid #999999;
        }

        &--ptb-18 {
          padding: 18px 0;
        }
      }
    }
  }
}

#cancelBooking {
  .cancel-modal {
    width: 603px;
    height: 100%;
    padding: 32px;
    background: #ffffff;

    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #131313;

    &__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 31px;
    }

    &__radioBox {
      display: block;

      span {
        padding-left: 12px;
      }

      label {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .area-other-cancel {
      width: 100%;
      padding: 10px;
      background: #f5f5f5;
    }

    &__button {
      padding-top: 32px;

      &-cancel {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11px 25px;
        background: #ffffff;
        border-radius: unset;
        margin-right: 31px;

        font-weight: bold;
        color: #af7756;
        border: 1px solid#AF7756;

        &:hover {
          opacity: 0.8;
        }
      }

      &-continue {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #af7756;
        border-radius: unset;
        padding: 11px 25px;

        font-weight: bold;
        color: #ffffff;
        border: unset;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    button:disabled,
    button[disabled] {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.required-star {
  color: #b3091b;
}

#backBooking {
  .back-modal {
    width: 480px;
    height: 100%;
    padding: 32px 46px 32px 32px;
    background: #ffffff;

    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #131313;

    &__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 31px;
    }

    &__button {
      padding-top: 32px;

      &-cancel {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 94px;
        height: 44px;
        background: #ffffff;
        border-radius: 4px;
        margin-right: 24px;

        font-weight: bold;
        color: #af7756;
        border: 1px solid #af7756;

        &:hover {
          opacity: 0.8;
        }
      }

      &-continue {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 94px;
        height: 44px;
        background: #af7756;
        border-radius: 4px;

        font-weight: bold;
        color: #ffffff;
        border: unset;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

// .printme {
//   display: none;
// }
@media print {
  .no-printme {
    display: none !important;
  }
  .printme {
    display: block;
  }
}
