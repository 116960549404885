@import '../../fonts/font-noto.scss';
@import '../../fonts/font-sarabun.scss';
@import '../../fonts/font-sawarabi.scss';
@import '../../fonts/font-noto-jp.scss';
@import '../../fonts/font-sawarabi-gothic.scss';
@import '../../fonts/font-roboto.scss';
@import '../../fonts/font-noto-sans-jp.scss';
@import '../../fonts/font-svn-quiche-sans.scss';
@import '../../fonts/font-noto-serif-cjk-jp.scss';
/* about isn */
.overview-company {
  margin-bottom: 70px;
  .overview-company-image {
    height: 280px;
    position: relative;
    .company-image-link {
      cursor: auto;
    }
    &:hover {
      .overview-company-overlay {
        background-color: transparent;
        transition: all 0.2s ease-in;
      }
    }
    .overview-company-overlay {
      background-color: $color_black_2;
      position: absolute;
      top: 0;
      opacity: 0.5;
      z-index: 1;
      cursor: pointer;
    }
    .overview-company-title {
      position: absolute;
      z-index: 2;
      bottom: 36px;
      left: 36px;
      font-size: 36px;
      color: $color_white_2;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.53;
      letter-spacing: normal;
      text-align: left;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-family: 'SVN-Quiche Sans';
      line-height: 1;
    }
    .title-name {
      margin-right: 32px;
      
    }
    .see-more {
      position: relative;
      z-index: 2;
      
      &:hover {
        .cls-1 {
          fill: $color_white_2;
          stroke: $color_red_1;
          cursor: pointer;
          transition: all 0.2s ease-in;
        }
      }
    }
  }
}

/*profile company*/
.profile-company {
  background-color: $color_gray_1;
  .profile-banner {
    width: 100%;
    height: 280px;
    position: relative;
    margin-bottom: 36px;
  }
  .company-profile-title {
    font-size: $fs_48;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: $color_white_2;
    position: absolute;
    bottom: 36px;
    z-index: 2;
    text-transform: capitalize;
  }
  .profile-table {
    padding: 80px;
    background-color: $color_white_2;
    border-radius: 4px;
    table {
      margin-bottom: 0px;
      tr {
        border-radius: 4px;
        td {
          padding: 16px;
          vertical-align: unset;
          border-top: none;
          text-align: left;
          &:first-child {
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: $color_black_2;
            opacity: 0.6;
          }
        }
        th {
          padding: 16px;
          vertical-align: unset;
          border-top: none;
        }
      }
    }
    .company-profile-border {
      border-bottom: 2px solid $color_gray_16;
      .profile-title {
        position: relative;
        padding: 24px 0;
        height: 100%;
        color: $color_gray_17;
        align-items: center;
        display: flex;
        &::before {
          content: '';
          border-bottom: 3px solid $color_gray_18;
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: -2px;
          left: 0;
        }
      }
      .profile-description {
        padding: 24px 0;
        height: 100%;
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
  .profile-widget {
    text-transform: uppercase;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: $color_black_2;
    padding: 19px 16px 18px 16px;
    background-color: $color_white_2;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: $fs_16;
    
  }
}
// menu right about isn
.profile-widget {
  text-transform: uppercase;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  padding: 19px 16px 18px 16px;
  background-color: $color_white_2;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: $fs_16;

  &:not(:first-child) {
    margin-top: 16px;
  }
  svg {
    transform: rotate(-90deg);
  }
  span {
    flex-grow: 1;
    &:hover {
      color: $color_red_1;
      transition: all 0.2s ease-in;
      text-decoration-color: $color_red_1;
      text-decoration-line: underline;
    }
  }
  &.click-active {
    color: $color_red_1;
    transition: all 0.2s ease-in;
  }
  &.turn-icon {
    svg {
      transition: all 0.2s ease-in;
      transform: rotate(0deg);
    }
  }
  &:hover {
    color: $color_red_1;
    transition: all 0.2s ease-in;
  }
}
.profile-widget-menu {
  background-color: $color_white_2;
  div {
    padding-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: left;
    a {
      color: $color_black_2;
      width: 100%;
      &.click-active {
        color: $color_red_1;
        transition: all 0.2s ease-in;
      }
    }
    cursor: pointer;
    a:hover {
      color: $color_red_1;
      transition: all 0.2s ease-in;
    }
    &:first-child {
      border-top: 1px solid $color_gray_1;
    }
    &:last-child {
      padding-bottom: 16px;
    }
  }
}

/*group companies*/
.group-company {
    @media screen and (min-width: 992px){
        .company-philosophy-text {
            position: absolute  !important;
            bottom: 38px;
        }
    }
  text-align: left;
  padding-bottom: 70px;
  background-color: $color_gray_1;
  .group-banner {
    height: 280px;
    position: relative;
    .group-company-overlay {
      background-color: $color_black_2;
      position: absolute;
      top: 0;
      opacity: 0.5;
      z-index: 0;
    }
    .group-company-title {
      font-size: $fs_48;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: $color_white_2;
      position: absolute;
      bottom: 36px;
      z-index: 2;
      font-family: 'SVN-Quiche Sans';
    }
  }
  .group-info {
    background-color: $color_white_2;
    padding: 25px 16px 24px 19px;
    border-radius: 4px;
    &:not(:first-child) {
      margin-top: 24px;
    }
    .group-info-content {
      border-top: 1px solid $color_gray_1;
      padding-top: 17.5px;
      margin-top: 13.5px;
      .row div.col-2:not(:nth-child(1)) {
        padding-top: 16px;
      }
      .row div.col-10:not(:nth-child(2)) {
        padding-top: 16px;
      }
      .capitalize-group-title {
        text-transform: capitalize;
      }
    }
  }
  .our-team-content {
    margin-bottom: 70px;
  }

  .our-team-carousel {
    img {
      border-radius: 3px;
    }
    .img-our-team {
      position: absolute;
    }
    .owl-theme {
      .owl-nav {
        top: 45%;
        width: 100%;
        display: flex;
        [class*='owl-'] {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          background: $color_white_2;
          &:hover {
            background: $color_black_2;
            svg {
              path:last-child {
                stroke: $color_white_2;
              }
            }
          }
        }
        .owl-prev {
          position: absolute;
          left: 20px;
        }
        .owl-next {
          position: absolute;
          right: 20px;
        }
      }
    }
  }

  .our-team-block {
    margin-top: 36px;
    &:first-child {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
    &:nth-child(3) {
      margin-top: 0;
    }
    .our-team-list {
      padding: 10px 10px;
      text-align: center;

      .our-team-image {
        width: 201px;
        height: 201px;
        border-radius: 50%;
      }

      .our-team-name {
        margin-top: 30px;
        font-weight: 500;
      }

      .our-team-position {
        color: $color-blue_1;
        font-size: $fs_14;
        margin: 10px 0;
      }
    }
  }

  .group-info-title {
    display: flex;
    align-items: flex-end;
    padding-bottom: 13.5px;
  }
  .corporation-name {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: $color_red_1;
    flex-grow: 1;
    font-size: 22px;
    margin-bottom: 0px;
  }
  .visit-web {
    font-size: $fs_14;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    img {
      width: 14px;
      height: 14px;
      object-fit: contain;
    }
  }
  .group-info-head ul {
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    display: flex;
    align-items: center;
    li {
      display: inline;
      a {
        color: $color_red_1 !important;
      }
    }
  }
}
.company-philosophy {
  height: 420px;
  width: 100%;
}
.company-philosophy-number {
  font-size: $fs_48;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
}
.company-philosophy-title {
  font-size: $fs_26;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
}
.company-philosophy-line {
  width: 60px;
  height: 3px;
  background-color: $color_red_1;
  margin-inline-end: unset;
  margin-inline-start: unset;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}
.company-philosophy-content {
  font-size: $fs_18;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
}
.company-philosophy-text {
  background-color: $color_white_2;
  padding: 36px 36px 33px 36px;
}
.philosophy-block {
  position: relative;
  &:nth-child(2) {
    margin-top: 70px;
    .company-philosophy-text {
      padding: 73px 35px 73px 36px;
      right: 15px;
    }
  }
}
.company-feature {
  background-color: $color_white_2;
  position: relative;
  z-index: 1;
  height: auto;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px 30px 40px 110px;
  text-align: left;
}
.company-feature-image {
  height: 120px;
  width: 120px;
  background-color: $color_red_1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  transform: translateX(-50%);
}
.feature-title {
  font-size: $fs_22;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: $color_black_2;
}
.feature-line {
  width: 60px;
  height: 3px;
  background-color: $color_black_2;
  margin-top: 16px;
  margin-bottom: 16px;
}
.feature-description {
  font-size: $fs_16;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: $color_black_2;
}
.company-feature-block {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
.history-list {
  background-color: $color_white_2;
  padding-top: 36px;
  padding-bottom: 36px;
  border-radius: 4px;
}
.history-data {
  &:not(:last-child) {
    border-bottom: 1px solid $color_gray_1;
    padding-bottom: 18px;
  }
  &:first-child {
    .history-text {
      padding-top: 0 !important;
    }
    .history-year {
      padding-top: 0 !important;
    }
    .history-month {
      padding-top: 0 !important;
    }
  }
}
.history-year {
  font-size: $fs_16;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  padding-top: 18px;
}
.history-month {
  font-size: $fs_16;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: $color_red_1;
  padding-top: 18px;
}
.history-text {
  padding-top: 18px;
}
.main-services-title {
  font-size: $fs_28;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: $color_red_1;
  text-transform: capitalize;
  &:not(:first-child) {
    padding-top: 70px;
  }
}
.service-block {
  background-color: $color_white_2;
  padding: 20px 20px 28px 24px;
  height: 100%;
  border-radius: 4px;
  margin-top: 23px;
}
.service-number {
  font-size: $fs_36;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: $color_red_1;
  padding-top: 16px;
}
.service-content-title {
  font-size: $fs_16;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  padding-top: 16px;
  height: 55px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.main-service-line {
  width: 48px;
  height: 2px;
  background-color: $color_red_1;
  margin-inline-end: unset;
  margin-inline-start: unset;
  margin-block-start: 12px;
  margin-block-end: 12px;
}
.service-description {
  font-size: $fs_16;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  display: -webkit-box;
  max-width: 200px;
  // -webkit-line-clamp: 9;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
}
.choose-us-block {
  background-color: $color_white_2;
  width: 100%;
  margin-top: 25px;
  &:first-child {
    margin-top: 0px !important;
  }
  .why-choose-image {
    height: 100%;
    width: 100%;
  }
}
.choose-us-title {
  font-size: $fs_28;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  border-left: 6px solid $color_red_1;
  padding-left: 12px;
  max-width: 215px;
}
.choose-us-line {
  width: 6px;
  height: 68px;
  background-color: $color_red_1;
}
.choose-us-content {
  padding: 36px 102px 37px 27px;
}
.choose-us-description {
  font-size: $fs_16;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  margin-top: 24px;
}
.choose-us-block-title {
  font-size: $fs_24;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  margin-top: 70px;
  text-transform: uppercase;
}
.choose-us-video {
  margin-top: 24px;
}

/* news-release */
.new-release-block {
  background-color: $color_white_2;
  border-radius: 4px;
  width: 100%;
  .news-banner {
    height: 258px;
    border-radius: 4px;
  }
}
.new-release {
  .new-release-data {
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
  }
  .new-release-block:hover {
    box-shadow: 0 3px 10px 0 $color_black_14;
    transition: all 0.2s ease-in;
  }
}
.new-release-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: $fs_20;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_7;
  margin: 20px 36px 14px 24px;
  height: 48px;
}
.new-release-property {
  ul {
    list-style-type: none;
    padding-inline-start: 24px;
    padding-bottom: 27px;
    li {
      display: inline-block;
      opacity: 0.7;
      color: $color_black_2;
    }
    .icon-clock {
      padding-right: 10px;
    }
  }
}

/*contact us of about-ISN*/
.contact-map {
  overflow: hidden;
  padding-bottom: 21%;
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    width: 100%;
    position: absolute;
  }
}
.contact-information {
  margin-top: 70px;
  background-color: $color_white_2;
  padding: 36px 24px 24px 24px;
  height: 314px;
  position: relative;
  transition: all 1.2s ease-in-out;
  border-radius: 4px;
  .contact-block-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 1.2s ease-in-out;
    border-radius: 4px;
  }
  .contact-overlay {
    background: linear-gradient(to top, $color_black_2, $color_black_5);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 1.2s ease-in-out;
    border-radius: 4px;
  }
  &:hover {
    transition: all 1.2s ease-in-out;
    .contact-block-hover {
      opacity: 1;
      z-index: 5;
    }
    .contact-overlay {
      opacity: 1;
      z-index: 6;
    }
    .contact-office-number,
    .contact-office-name,
    .contact-address,
    .contact-mail,
    .contact-phone {
      color: $color_white_2;
      position: relative;
      z-index: 10;
    }
    .contact-form-line {
      background-color: $color_white_2;
      position: relative;
      z-index: 10;
    }
    .contact-icon {
      filter: invert(1);
    }
  }
}
.contact-office-name {
  font-size: $fs_20;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  text-transform: uppercase;
  margin-top: 16px;
}
.contact-address {
  font-size: $fs_16;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
}
.contact-mail {
  font-size: $fs_16;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  padding-top: 17px;
  .contact-icon {
    object-fit: initial;
  }
}
.contact-phone {
  font-size: $fs_16;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: $color_black_2;
  padding-top: 17px;
  padding-bottom: 36px;
}
.address-information {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.contact-office-number {
  font-size: $fs_36;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: $color_red_1;
}
.contact-form {
  background-color: $color_white_2;
  padding: 50px;
  border-radius: 4px;
  .form-contact-button {
    display: flex;
    justify-content: flex-end;
    button {
      max-width: 125px;
      width: 100%;
      font-size: $fs_14;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.07;
      letter-spacing: normal;
      color: $color_white_2;
      border-radius: 0px !important;
    }
  }
  #send {
    background-color: $color_red_1;
    border-color: $color_red_1 !important;
    transition: all 0.2s ease-in;
    &:not([disabled]){
      &:hover {
        opacity: 0.8 !important;
      }
    }
  }
  #cancel {
    background-color: transparent;
    border-color: $color_red_1 !important;
    color: $color_red_1;
    transition: all 0.2s ease-in;
    &:hover {
      opacity: 0.8;
    }
  }
  .g-recaptcha {
    height: 53px;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .area-requirement {
    height: 125px;
  }
  .contact-form-title {
    text-transform: uppercase;
    font-size: $fs_24;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: $color_black_2;
  }
  .contact-content {
    margin-top: 12px;
  }
  .contact-form-head {
    font-size: $fs_14;
    .text-right {
      height: 50px;
      img {
        width: 117px;
        height: 72px;
        opacity: 0.8;
        object-fit: contain;
      }
      @media (max-width: 576px ) {
          img {
            width: 65px;
        }
      }
    }
  }
}
.contact-form-line {
  width: 48px;
  height: 2px;
  background-color: $color_red_1;
  margin-inline-start: unset;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

#contact {
  .show {
    z-index: 0 !important;
  }
  .form-group {
    input {
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    label {
      font-size: $fs_16;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: $color_black_2;
    }
  }
}
#meetOurTeam{
  padding-bottom: 30px !important;
  .owl-dots{
    position: relative !important;
    top: -35px !important;
  }
}